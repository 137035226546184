import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private authService: AuthService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canLoadOrActivate();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canLoadOrActivate();
  }

  // tslint:disable-next-line:max-line-length
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canLoadOrActivate();
  }

  private canLoadOrActivate(): Observable<boolean> | Promise<boolean> {
    const code = this.getParameterByName('code');
    if (code) {
      this.authService.showFailMessage(false);
      return new Promise<boolean>((resolve, reject) => {
        this.authService.retrieveToken(code).toPromise()
          .then(data => {
            this.authService.saveToken(data);
            resolve(true);
          })
          .catch(err => {
            if (err.status === 403) {
              this.authService.logout();
            } else {
              this.authService.showFailMessage(true);
            }

            reject(false);
          });
      });
    } else {
      return this.checkLogin();
    }
  }

  private getParameterByName(name: string, url = window.location.href): string | null{
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  checkLogin(): Promise<boolean> {
    return new Promise<boolean>( resolve => {
      // resolve(true);
      // return;
      this.authService.checkCredentials().then(isValid => {
        if (isValid) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}
