import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

export enum FieldType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  TIME = 'TIME',
  CHECKBOX = 'CHECKBOX',
  TABLE = 'TABLE',
  LIST = 'LIST',
  CHIPS = 'CHIPS',
  TEXTAREA = 'TEXTAREA',
  HIDDEN = 'HIDDEN',
  SLIDER = 'SLIDER',
  PASSWORD = 'PASSWORD',
  SELECT = 'SELECT',
  OBJECT = 'OBJECT'
}

export interface FormField {
  id?: string;
  name: string;
  value?: string;
  type?: FieldType;
}
@Component({
  selector: 'app-base-field',
  templateUrl: './base-field.component.html',
  styleUrls: ['./base-field.component.scss']
})
export class BaseFieldComponent implements OnInit, OnChanges {

  fieldType!: FieldType;

  @Input()
  formGroup: FormGroup = new FormGroup({});

  @Input()
  data!: any;

  @Input()
  id!: string;

  @Input()
  name!: string;

  @Input()
  description!: string;

  @Input()
  dbname!: string;

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  whereClause!: string;

  @Input()
  autoHide = true;

  @Input()
  styleClass = '';

  @Input()
  inputSize = 'md';

  @Output()
  changed = new EventEmitter<FormField>();

  onHover = false;
  onFocus = false;

  formControl: FormControl = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.formGroup.addControl(this.dbname, this.formControl);
  }

  public getValue(): any {
    return this.formControl.value;
  }

  public changeInput(event: any): void {
    this.formControl.setValue(event.target.value);
    this.changed.emit({id: this.id, name: this.dbname, value: event.target.value, type: this.fieldType});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data && this.formControl.value !== this.data) {
      this.formControl.setValue(this.data);
    }
  }

  getFormGroupClass(): string {
    if (this.inputSize === 'sm') {
      return 'form-group form-group-sm';
    }
    return 'form-group';
  }

  /* getFormControlClass(): string {
    if (this.inputSize === 'sm') {
      return (this.autoHide ?
        'form-control form-control-sm cursor-text' :
        'form-control form-control-sm form-control-border cursor-text') + ' ' + this.styleClass;
    }

    return (this.autoHide ? 'form-control' : 'form-control form-control-border cursor-text')
      + (this.disabled ? ' disabled ' : '')
      + ' ' + this.styleClass;
  } */


        getFormControlClass(): string {
          const baseClass = this.inputSize === 'sm'
            ? 'form-control form-control-sm cursor-text'
            : 'form-control cursor-text';

          const autoHideClass = this.autoHide ? '' : 'form-control-border';
          const disabledClass = this.disabled ? ' disabled' : '';

          return `${baseClass} ${autoHideClass} ${this.styleClass}${disabledClass}`.trim().replace(/\s\s+/g, ' ');
        }

  getInputGroupClass(): string {
    const focus = this.onFocus ? 'focused ' : this.onHover ? 'hovered ' : '';
    if (!this.autoHide) {
      return `input-group input-group-border ${focus}`;
    } else if (!this.formControl.valid) {
      return `input-group invalid ${focus}`;
    }

    return `input-group ${focus}`;
  }

  setHover(value: boolean): void {
    if (!this.disabled) {
      this.onHover = value;
    }
  }

  setFocus(value: boolean): void {
    if (!this.disabled) {
      this.onFocus = value;
    }
  }

}
