export const environment = {
  production: true,
  url: 'https://lite.legalmanager.com.br/',
  apiUrl: 'https://lite.legalmanager.com.br/bff/api',
  oauth2Url: 'https://oauth-authorization-server-pwz4v.ondigitalocean.app/realms/legal',
  oauth2ClientId: 'legal-light',
  oauth2TokenUrl: 'https://lite.legalmanager.com.br/bff/api/auth/token',
  msRedirectUri: 'https://oauth-authorization-server-pwz4v.ondigitalocean.app/integrations/microsoft/oauth2/redirect',
  msAuthority : 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  msClientId: '8d45ad5e-e1b9-4b2c-9e10-08e7f23756bf'
};
