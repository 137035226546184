<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
  <div class="container-fluid">
    <div>
      <button class="mr-1 ml-0 mb-0 mt-0 menu-toggle" mat-icon-button (click)="snav.toggle()">
        <mat-icon class="text-white menu-toggle">menu</mat-icon>
      </button>

      <!-- Brand -->
      <img class="logo-navbar ml-1" src="../assets/img/brand/logo-branco-roxo-texto.png">
    </div>

    <h3 *ngIf="getTitle() !== ''"
        class="h3 mb-0 mt-3 ml-3 text-white text-uppercase d-none d-lg-inline-block"
    >{{ getTitle() }}</h3>


    <!-- Form -->
    <form
      class="navbar-search navbar-search-dark form-inline d-none d-md-flex ml-lg-auto"
    >
      <!-- Toolbar -->
      <div
        class="text-right mr-4"
        *ngIf="getToolbar() && getToolbar().length > 0"
      >
        <a
          *ngFor="let toolbar of getToolbar()"
          class="mr-2 ml-2"
          href="javascript:void(0)"
          (click)="executeCallback(toolbar.callback, toolbar.params)"
          matTooltip="{{toolbar.title}}"
        ><i
          class="fas text-white"
          [className]="'fas text-white ' + toolbar.icon"
        ></i
        ></a>
      </div>

      <div class="form-group mb-0">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" [placeholder]="'core.navbar.search.placeholder' | translate" type="text"
                 value="{{filterText}}"
                 (change)="generalSearchChange($event)"
                 (input)="generalSearchInput($event)"
                 (keydown.enter)="generalSearchEnter($event)"
                 (focus)="generalSearchFocus(true)"
                 (blur)="generalSearchFocus(false)">
        </div>
      </div>

      <div ngbDropdown placement="bottom-right" #generalSearchDropdown>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div
            class="generalSearchPanel"
            *ngFor="let generalSearch of generalSearchOptions">

            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">
                {{ generalSearch.route }}
              </h6>
            </div>

            <div class="ml-1" *ngFor="let generalEntity of generalSearch.generalEntity">
              <a
                routerLinkActive="active"
                [routerLink]="[generalSearch.route, generalEntity.id]"
                class="dropdown-item">{{ generalEntity.name }}</a>

            </div>
          </div>
          <div class="generalSearchPanel" *ngIf="generalSearchOptions?.length == 0">
            <div class="dropdown-header noti-title">
              <h6 class="text-center m-0">{{ 'core.navbar.search.not_found' | translate }}</h6>
            </div>
          </div>
          <div class="dropdown-divider"></div>
        </div>
      </div>

    </form>

    <div class="ml-3 d-none d-md-flex" ngbDropdown placement="bottom-right">
      <a href="javascript:void(0)" class="notification" ngbDropdownToggle>
        <i class="fa-solid fa-bell fa-2x"></i>
        <span class="notification__badge" *ngIf="hasUnreadNotification()"></span>
      </a>
      <div class="dropdown-menu-arrow dropdown-menu-right mt-4" ngbDropdownMenu>
        <div class="notification__body">
          <div class="notification__card text-right mt-0">
            <a href="javascript:void(0)" (click)="markAllAsRead()">
              <i class="fa-solid fa-xs fa-check-double mr-2"></i>
              <span class="small">{{ 'core.notification.mark_all_as_read' | translate }}</span>
            </a>
          </div>
          <div class="notification__card cursor-pointer" *ngFor="let notification of notifications"
               (mouseenter)="startReadTimer(notification)" (mouseleave)="cancelReadTimer()"
               (click)="readAndRedirect(notification)">
            <h6 class="text-mutted text-uppercase mb-0">{{ notification.title }}</h6>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <p class="mb-0" [innerHTML]="notification.description">Novos andamentos chegaram ao processo <b>1001594-19.2022.5.02.0054</b>
              </p>
              <i class="fa-solid fa-circle fa-2xs text-danger ml-2" [hidden]="notification.read"></i>
            </div>
          </div>
          <div class="notification__card" *ngIf="notifications.length === 0">
            <p class="text-center">{{ 'core.notification.not_found' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- User -->


    <ul class="navbar-nav align-items-center d-none d-md-flex">


      <li class="nav-item" ngbDropdown placement="bottom-right">

        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <app-avatar [entityWithAvatar]="user" size="sm"></app-avatar>
          </div>
        </a>

        <div class="dropdown-menu-arrow dropdown-menu-right mt-4" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{ user?.name }}</h6>
          </div>
          <!-- <a
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="showTenantOptions()"
            class="dropdown-item"
            *ngIf="this.userTenants && this.userTenants.length > 1"
          >
            <i class="ni ni-single-02"></i>
            <span>{{ 'core.user_menu.switch_workspace' | translate }}</span>
          </a> -->
          <a
            routerLinkActive="active"
            [routerLink]="['/user_settings']"
            class="dropdown-item"
          >
            <i class="ni ni-settings-gear-65"></i>
            <span>{{ 'core.user_menu.settings' | translate }}</span>
          </a>

          <a
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="updatePasswordAndRedirect()"
            class="dropdown-item"
          >
            <i class="fa fa-key"></i>
            <span> {{ 'core.user_menu.reset_password' | translate }}</span>
          </a>

          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" (click)="logout()">
            <i class="ni ni-user-run"></i>
            <span>{{ 'core.user_menu.logout' | translate }}</span>
          </a>
        </div>
      </li>



    </ul>

       <!-- User -->

  </div>
</nav>
