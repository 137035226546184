import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreComponent} from './core.component';
import {SpeedDialFabComponent} from './speed-dial-fab/speed-dial-fab.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserSelectComponent} from './user-select/user-select.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {AppFormsModule} from '../../app-layout/components/forms/app-forms.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { StripHtmlPipe } from './pipe/strip-html.pipe';
import {SafeHtmlPipe} from '../../infrastructure/pipe/safe-html.pipe';


@NgModule({
  declarations: [
    CoreComponent,
    SpeedDialFabComponent,
    UserSelectComponent,
    StripHtmlPipe,
    SafeHtmlPipe
  ],
  exports: [
    CoreComponent,
    SpeedDialFabComponent,
    UserSelectComponent,
    StripHtmlPipe,
    SafeHtmlPipe
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        NgbDropdownModule,
        RouterModule,
        AppFormsModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatOptionModule,
        ReactiveFormsModule,
        TranslateModule
    ]
})
export class CoreModule { }
