import {Injectable} from '@angular/core';
import {ApiService} from '../../../../infrastructure/service/api.service';
import {HttpClient} from '@angular/common/http';
import {AutoCompleteOption} from './auto-complete-option';

@Injectable({
  providedIn: 'root'
})
export class AutoCompleteService extends ApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  findById(url: string, id: number): Promise<AutoCompleteOption> {
    return this.get(`${url}/${id}`).toPromise();
  }
}
