import {Injectable} from '@angular/core';
import {User} from './user';
import {ApiService} from '../service/api.service';
import {AutoSaveService} from 'src/app/modules/auto-save/service/auto-save.service';
import {HttpClient} from '@angular/common/http';
import {UserTenant, UserTenantRole} from './user-tenant';
import {TranslateService} from '@ngx-translate/core';
import {Tenant} from './tenant';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  private serviceUrl = 'auth';

  userTenantRole = UserTenantRole;

  autoSaveService: AutoSaveService;

  constructor(http: HttpClient,
              autoSaveService: AutoSaveService,
              private translate: TranslateService) {
    super(http);
    this.autoSaveService = autoSaveService;
  }

  public getLoggedUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.get(this.serviceUrl + '/login').toPromise().then( u => {
        this.saveLocalUser(u);
        this.getUserTenants().then(ut => {
          this.saveLocalUserTenants(ut);
        });
        resolve(u);
      }).catch(err => reject(err));
    });
  }

  public getLocalUser(): Promise<User> {
    return this.get(this.serviceUrl + '/login').toPromise();
  }

  public getUserTenants(): Promise<UserTenant[]> {
    return new Promise<UserTenant[]>((resolve, reject) => {
      this.get(this.serviceUrl + '/tenants').toPromise().then(ut => {
        this.saveLocalUserTenants(ut);
        resolve(ut);
      }).catch(err => reject(err));
    });
  }

  public updateAvatar(avatar: string | undefined): Promise<User> {
    const methodUrl = `${this.serviceUrl}/avatar`;
    return this.patch(methodUrl, avatar).toPromise();
  }

  public updateTenantAvatar(avatar: string | undefined): Promise<User> {
    const methodUrl = `${this.serviceUrl}/tenant_avatar`;
    return this.patch(methodUrl, avatar).toPromise();
  }

  public saveLocalUser(user: User): void {
    sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify(user));

    const lang: string = user.language || this.translate.currentLang;
    localStorage.setItem('locale', lang);
    this.translate.use(lang);
  }

  public getCurrentLanguage(): string {
    return localStorage.getItem('locale') ?? 'pt-BR';
  }

  public saveLocalUserTenant(userTenant: UserTenant): void {
    sessionStorage.setItem('user-tenant', JSON.stringify(userTenant));
    localStorage.setItem('user-tenant', JSON.stringify(userTenant));
  }


  public saveLocalUserTenants(userTenant: UserTenant[]): void {
    localStorage.setItem('user-tenants', JSON.stringify(userTenant));
  }

  public loadLocalUser(): User | undefined {
    const item = localStorage.getItem('user');
    return item ? JSON.parse(item) : undefined;
  }

  public loadLocalUserTenants(): UserTenant[] | undefined {
    const item = localStorage.getItem('user-tenants');
    return item ? JSON.parse(item) : undefined;
  }

  public loadLoggedUserTenant(): UserTenant | undefined {
    const item = sessionStorage.getItem('user-tenant')
      || localStorage.getItem('user-tenant');

    const localUserTenants = this.loadLocalUserTenants();
    try {
      if (item) {
        const loggeduserTenant = localUserTenants?.find(ut => ut.id === JSON.parse(item ?? '').id);
        if (loggeduserTenant) {
          return loggeduserTenant;
        }
      }
    } catch (e) {}

    return localUserTenants?.find(ut => ut.role === this.userTenantRole.PRINCIPAL);
  }

  public switchTenant(userTenant: UserTenant): void {
    sessionStorage.setItem('user-tenant', JSON.stringify(userTenant));
    localStorage.setItem('user-tenant', JSON.stringify(userTenant));
  }

  public isAdm(): boolean {
    const userTenants = this.loadLocalUserTenants();
    const loggedTenant = this.loadLoggedUserTenant();
    if (userTenants) {
      if (loggedTenant) {
        return userTenants.find(ut => ut.id === loggedTenant.id)?.role === this.userTenantRole.PRINCIPAL;
      } else {
        return false;
      }
    }
    return false;
  }

  public isTenantPrincipal(): boolean {
    const loggedTenant = this.loadLoggedUserTenant();
    if (loggedTenant) {
      return loggedTenant?.role === this.userTenantRole.PRINCIPAL;
    } else {
      return false;
    }
    return false;
  }

  public createNewTenant(newTenantName: string): Promise<UserTenant> {
    const methodUrl = `${this.serviceUrl}/new-tenant`;
    return this.post(methodUrl, newTenantName).toPromise();
  }

  userRoleDisabled(): boolean {
    /* let role : string = JSON.parse((sessionStorage.getItem('user-tenant')
      || localStorage.getItem('user-tenant')) ?? "").role ?? "" ; */

    const role: string = this.loadLoggedUserTenant()?.role ?? 'READ';

    if (role === 'READ') {
      return true;
    }
    return false;

  }

  findByName(name : string) : Observable<User[]>{
    const methodUrl = `${this.serviceUrl}/user`;
   return this.post(methodUrl, name);

  }

}
