import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { WebSocketHandler } from './web-socket-handler';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../auth/user.service';
import { WSMessage, WSAction } from '../domain/ws-message';
import { FieldType } from '../../app-layout/components/forms/base-field/base-field.component';
import { AutoCompleteOption } from 'src/app/app-layout/components/forms/auto-complete/auto-complete-option';
import { ApiService } from './api.service';
import { Subtask, TaskSchema } from '../../modules/task/domain/task';
import { Label } from 'src/app/modules/label/domain/label';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends ApiService {

  webSocketEndPoint = `${environment.apiUrl}/ws`;

  // wsInstances: WebSocketServiceInstance[] = [];

  constructor(private userService: UserService, http: HttpClient) {
    super(http)
  }

  connect(topic: string, handler: WebSocketHandler): WebSocketServiceInstance {
    const tenantId: string = this.userService.loadLoggedUserTenant()?.tenant?.id || '-1';
    const wsInstance: WebSocketServiceInstance = new WebSocketServiceInstance(this.webSocketEndPoint, topic, handler, tenantId);
    wsInstance.connect();
    return wsInstance;
  }

  defaultHandleMessage(message: WSMessage, entity: any, schema?: any): any {
    if (!message.subtopic) {

      const id: string = message.object.id;

      if (id === entity.id) {

        this.handleMessage(message, entity, schema);
      }
      else {

      }
    } else {

      entity[message.subtopic] = entity[message.subtopic] || [];

      if (message.action === 'DELETED') {
        entity[message.subtopic] = entity[message.subtopic].filter((item: { id: string }) => item.id !== message.object.id);
        return;
      }

      const idxSubtopic = entity[message.subtopic].findIndex((t: { id: string; }) => t.id === message.object.id);
      if (idxSubtopic === -1) {
        entity[message.subtopic].push(message.object);
        this.handleMessage(message, entity[message.subtopic][entity[message.subtopic].length - 1], schema[message.subtopic]);
      } else {
        this.handleMessage(message, entity[message.subtopic][idxSubtopic], schema[message.subtopic]);
      }
    }
  }

  private handleMessage(message: WSMessage, entity: any, schema?: any): any {
    Object.keys(message.object).forEach(k => {
      if (message.object[k]) {
        if (schema && schema[k]) {
          if (schema[k].type === FieldType.DATE) {
            entity[k] = new Date(message.object[k]);

          } else if (schema[k].type === FieldType.TIME) {
            entity[k] = Date.parse('1970-01-01 ' + message.object[k]);

          } else if (schema[k].type === FieldType.AUTOCOMPLETE && (message.object[k].id && message.object[k].name === null)) {
            this.findByIdSimple(schema[k].findByIdUrl, message.object[k].id).then(value => {
              entity[k] = value;
            });

          } else {
            entity[k] = message.object[k];
          }
        }
      }
    });


    message.deletedFields?.forEach(k => entity[k] = null);


  }

  findByIdSimple(url: string, id: string): Promise<any> {
    //alert(`${url}/${id}/simple`);
    return this.get(`${url}/${id}/simple`).toPromise();
  }


}

export class WebSocketServiceInstance {

  stompClient: any;

  private webSocketEndPoint: string;
  private topic: string;
  private handler: WebSocketHandler;
  private tenantId: string;

  constructor(webSocketEndPoint: string, topic: string, handler: WebSocketHandler, tenantId: string) {
    this.webSocketEndPoint = webSocketEndPoint;
    this.topic = topic;
    this.handler = handler;
    this.tenantId = tenantId;
  }

  connect(): void {
    // console.log('Initialize WebSocket Connection');
    const ws = new SockJS(`${this.webSocketEndPoint}?access_token=${localStorage.getItem('access_token')}`);
    this.stompClient = Stomp.over(ws);

    const headers: any = this.prepareHeaders();
    const _this = this;
    const topicUri = this.topic.charAt(0) === '/' ? `/topic/${this.tenantId}${this.topic}` : `/topic/${this.tenantId}/${this.topic}`;
    _this.stompClient.connect(headers, (frame: any) => {
      _this.stompClient.subscribe(topicUri, (sdkEvent: any) => _this.onMessageReceived(sdkEvent), headers);
      _this.stompClient.reconnect_delay = 2000;
    }, (error: any) => {
      // console.log('errorCallBack -> ' + error);
      setTimeout(() => {
        _this.connect();
      }, 5000);
    });
  }

  private prepareHeaders(): any {
    return { Authorization: 'Bearer ' + localStorage.getItem('access_token') };

    // let headers: any = new HttpHeaders();
    // headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    // headers = headers.append('Accept-Language', localStorage.getItem('locale') || 'en-US');
    // const userTenant = sessionStorage.getItem('user-tenant') || localStorage.getItem('user-tenant');
    // if (userTenant) {
    //   headers = headers.append('X-TenantID', JSON.parse(userTenant).tenant.id);
    // }
    // return headers;
  }

  disconnect(): void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    // console.log('Disconnected');
  }

  send(message: string): void {
    // console.log('calling logout api via web socket');
    this.stompClient.send('/app/hello', this.prepareHeaders(), JSON.stringify(message));
  }

  onMessageReceived(message: any): void {
    // console.log('Message Received from Server :: ' + message);
    this.handler.handleMessage(JSON.parse(message.body));
  }


}
