import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseFieldComponent, FieldType} from '../base-field/base-field.component';
import {AutoCompleteOption} from './auto-complete-option';
import {map, startWith} from 'rxjs/operators';
import {AutoCompleteService} from './auto-complete.service';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent extends BaseFieldComponent implements OnInit, OnChanges {

  @Input()
  options: any[] = [];

  @Input()
  serverSide = false;

  @Input()
  addNewOption = false;

  @Input()
  findByIdUrl!: string;

  @Output()
  filterChange = new EventEmitter<string>();

  @Output()
  added = new EventEmitter<string>();

  lastText = '';

  filterChangeTimeout: any;
  filterChangeSecondsInterval = 300;

  sourceData!: any;
  sourceOptions: AutoCompleteOption[] = [];
  filteredOptions!: AutoCompleteOption[];

  constructor(private service: AutoCompleteService) {
    super();
    this.fieldType = FieldType.AUTOCOMPLETE;
  }

  ngOnInit(): void {
    this.formGroup.addControl(this.dbname, this.formControl);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options) {
      this.sourceOptions = [];
      this.options.forEach(o => this.sourceOptions.push({id: o.id, name: o.name}));
      this._filter('');
    }

    if (this.data && this.sourceOptions) {
      const receivedData = this.data;
      const founded = this.sourceOptions.find(o => o.id === receivedData || o.id === receivedData.id);
      if (!this.data?.name && !founded && this.findByIdUrl) {
        this.service.findById(this.findByIdUrl, receivedData.id || receivedData).then( (d: any) => {
          this.data = d;
          this.sourceData = {id: this.data.id, name: this.data.name};
          this.sourceOptions.push(this.sourceData);
          this.formControl.setValue(this.sourceData);
        });
        return;
      } else if (founded) {
        this.data = founded;
      }
    }

    if (changes.data && this.data) {
      this.sourceData = {id: this.data.id, name: this.data.name};
      this.formControl.setValue(this.sourceData);
    }

    if (this.data && this.sourceOptions) {
      if (!this.sourceOptions.some(o => o.id === this.sourceData.id)) {
        this.sourceOptions.push(this.sourceData);
      }
    }
  }

  getDescription(option: any): string {
    return option?.name;
  }

  private _filter(value: any): void {
    if (typeof value === 'string') {
      const filterValue = value.trim().toLowerCase();

      this.filteredOptions = this.sourceOptions?.filter(option =>
        option.name.toLowerCase().includes(filterValue));
    }
  }

  public changeInput(event: any): void {

    if (!event.option.value || event.option.value === '') {
      if (this.lastText.trim() !== '') {
        this.added.emit(this.lastText);
        this.formControl.setValue(event.option.value);
      }
    } else {
      this.changed.emit({id: this.id, name: this.dbname, value: event.option.value.id, type: this.fieldType});
      this.formControl.setValue(event.option.value);
    }
  }

    keydownInput(event: any): void {
      this.lastText = event?.target?.value || '';
      clearTimeout(this.filterChangeTimeout);
      this.filterChangeTimeout = setTimeout(() => {
        this.filterChange.emit(event?.target?.value);
        if (!this.serverSide) {
          this._filter(event?.target?.value);
        }
      }, this.filterChangeSecondsInterval);

      if (event?.target?.value === '') {
        this.formControl.setValue(null); // Ajuste aqui para limpar o valor do controle do formulário
      }


    }

  searchChange(event: any): void {
    if (event?.target?.value === '') {
      this.changed.emit({id: this.id, name: this.dbname, value: undefined, type: this.fieldType});
    }
  }
}
